/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Colors */
@primary: red;;
@blue: #1083ff;
@gray: #9999b9;

.mat-table
{
  background-color:white!important;
  overflow:hidden !important;

}

.result-box{
  border: solid @blue;
  border-radius: 1em !important;
  padding: 3em;
  margin: 1rem;
  background-color: white;
}

.result-box th{
  color: @gray;
  text-transform: uppercase;
}


table {
  width: 90%;
  text-align: center;
  justify-content:center;

}
table tr:last-child td /*To remove the last border*/
{
  border-bottom:0 solid;
}

table tr{
 text-align: left;
}

.mat-menu-settings a {
  color: @blue;
}

.mat-h2{
  text-transform: uppercase;
  color: @gray;
  text-align: center;
}

.fx-spacer {
  flex: 1 1 auto;
}

.full-width{
  width: 100%;
}

.text-center{
  text-align: center;
}
.overflow{
  overflow-x: scroll;
}
.mat-table tr:nth-child(even){
  background-color:#c0ddfc;
}
table.mat-table{
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 0 8px;
  }
}

.result-box th{
  text-transform: none;
}
.mat-dialog-container {
  max-height: 90vh !important;
}
.overlay{
  position: fixed;
  z-index: 10;
  background-color: rgba(0,0,0,.6);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  mat-spinner, span{
    position: relative;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
  span{
    margin-top: 5px;
    display: block;
    color: white;
    left: calc(50% - 70px);
  }

}
